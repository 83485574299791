import { Card, Collection, Heading, View } from "@aws-amplify/ui-react";
import { IEventRequest, filterForKeyPositions, getPersonsRequests, makeEventObj } from "../helpers/sunday"
import { EventCard } from "./EventCard"
import { EventTitle } from "./EventTitle";
import { flow, get } from "lodash/fp";

interface NextEventProps {
  requests: IEventRequest[];
  forPersonId: number | null;
  title?: string;
}

export const NextEvent = ({requests, forPersonId, title = 'Next event'}: NextEventProps) => requests.length > 0 ? (
  <View paddingBottom='medium'>
    <Heading level={5} paddingBottom='small'>{title}</Heading>
    <Card
      paddingBottom='medium'
      borderRadius="small"
      borderColor="lightgray"
      borderWidth="1px"
    >
      <EventTitle event={flow(
        getPersonsRequests(forPersonId, true),
        get(0),
        makeEventObj,
      )(requests)} />
      <Collection type="list" items={filterForKeyPositions(requests)} marginBottom='medium'>
        {(request: IEventRequest, index) => (
          <EventCard key={index} request={request} />
        )}
      </Collection>
    </Card>
  </View>
) : <></>
