import { Badge, Collection, Heading, View } from "@aws-amplify/ui-react";
import { IEventRequest, getPersonsConfirmedRequests, getPersonsOpenRequests } from "../helpers/sunday"
import { RequestCard } from "./RequestCard";
import { flow } from "lodash/fp";
import { getLength } from "../helpers/functional";

interface RequestsCollectionProps {
  requests: IEventRequest[];
  confirmed: boolean;
  forPersonId: number | null;
  title?: string;
}

const byConfirmed = (confirmed: boolean, personId: number | null) => (req: IEventRequest) => req.confirmed === confirmed && req.personId === personId;
const openRequests = (confirmed: boolean, personId: number | null) => (requests: IEventRequest[]) => requests.filter(byConfirmed(confirmed, personId));
const numberOfOpenRequests = (confirmed: boolean, personId: number | null, requests: IEventRequest[]) => flow(
  openRequests(confirmed, personId),
  getLength,
)(requests);

export const RequestsCollection = ({requests, confirmed, forPersonId, title}: RequestsCollectionProps) => flow(
  (confirmed ? getPersonsConfirmedRequests : getPersonsOpenRequests)(forPersonId, true),
  getLength,
)(requests) > 0 ? (
  <View paddingBottom="medium">
    {title && (
      <Heading level={5} paddingBottom="small">
        {title} {!confirmed && numberOfOpenRequests(confirmed, forPersonId, requests) > 0 && (
          <Badge variation="warning" size="small">{numberOfOpenRequests(confirmed, forPersonId, requests)}</Badge>
        )}
      </Heading>
    )}
    <Collection type="list" items={requests.filter(byConfirmed(confirmed, forPersonId))}>
      {(request, index) => (
        <RequestCard key={index} request={request} otherContributors={getPersonsConfirmedRequests(forPersonId, false)(requests)} />
      )}
    </Collection>
  </View>
) : <></>