import { Dispatch, SetStateAction, useState } from "react";
import { IEventRequest, apiAcceptRequest, apiDeclineRequest, isPending } from "../helpers/sunday";
import { Alert, BaseButtonProps, Button, FieldGroupIconButton, Flex, TextField, View } from "@aws-amplify/ui-react";
import { FaCheck, FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useRefreshData } from "../pages/MainPage";

interface DeclineCommentTextFieldProps {
  setComment: Dispatch<SetStateAction<string>>;
  declineRequest: () => Promise<void>;
}

export const DeclineCommentTextField = ({setComment, declineRequest}: DeclineCommentTextFieldProps) => (
  <View marginTop="small" fontSize="small">
    <TextField
      placeholder="Please provide a comment for your leader"
      label="Comment"
      variation="quiet"
      labelHidden
      size="small"
      onChange={(e) => setComment(e.target.value)}
      innerEndComponent={
        <FieldGroupIconButton
          type='button'
          ariaLabel="Submit"
          variation="link"
          onClick={declineRequest}
        >
          <FaCheck />
        </FieldGroupIconButton>
      }
    />
  </View>
);

interface AcceptDeclineButtonProps extends BaseButtonProps {
  category: 'accept' | 'decline';
  hideText?: boolean;
}

export const AcceptDeclineButton = ({category, hideText = false, ...props}: AcceptDeclineButtonProps) => {
  return (
    <Button
      {...props}
      size='small'
      variation='link'
      gap="0.3rem"
      color={category === 'accept' ? undefined : 'red.60'}
    >
      {category === 'accept' ? 
        <><FaCheckCircle />{!hideText && 'Accept Request'}</> :
        <><FaTimesCircle />{!hideText && 'Decline'}</>
      }
    </Button>
  )
}

export const declineRequest = (
  setLoadingState: (status: boolean) => void,
  hideCommentField: () => void,
  request: IEventRequest,
  comment: string,
  refreshData: () => void,
) => async () => {
  setLoadingState(true);
  hideCommentField();
  await apiDeclineRequest(request, comment);
  refreshData();
}

interface AcceptDeclineButtonsProps {
  request: IEventRequest;
}

export const AcceptDeclineButtons = ({request}: AcceptDeclineButtonsProps) => {
  const [showCommentField, setShowCommentField] = useState(false);
  const [comment, setComment] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [updating, setUpdating] = useState(false);
  const refreshData = useRefreshData();

  const acceptRequest = async () => {
    setUpdating(true);
    setShowThankYou(true);
    await apiAcceptRequest(request);
    refreshData();
  }
  
  return (
    <>
      <Flex display="flex" direction="row" marginTop="small">
        {isPending(request) &&
          <AcceptDeclineButton category='accept' padding='0' onClick={acceptRequest} isLoading={updating} />
        }
        <AcceptDeclineButton category='decline' padding='0' onClick={() => setShowCommentField(true)} isLoading={updating} />
        {showThankYou && (
          <Alert variation="success" heading="Thank you!">for accepting the request and serving at Mosaik</Alert>
        )}
      </Flex>
      {showCommentField && (
        <DeclineCommentTextField declineRequest={declineRequest(
          setUpdating,
          () => setShowCommentField(false),
          request,
          comment,
          refreshData,
        )} setComment={setComment} />
      )}
    </>
  );
}
