import { Heading } from "@aws-amplify/ui-react";
import { createContext, useEffect, useState } from "react";
import { IAbsence, apiChurchToolsAbsences } from "../helpers/profile";
import { useAuth } from "../auth/AuthProvider";
import { Duration } from "../helpers/functional";

export const RefreshDataContext = createContext(() => {});

interface AbsencesProps {
  userId: number | null;
}  

export const Absences: React.FC<AbsencesProps> = ({userId}) => {
  const [absences, setAbsences] = useState([] as IAbsence[])
  const { logout } = useAuth();

  const refreshData = () => {
    apiChurchToolsAbsences(setAbsences, userId, logout);
  };

  useEffect(() => {
    refreshData();
    const interval = setInterval(refreshData, Duration.minutes(5));
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!userId) return <></>

  return (
    <RefreshDataContext.Provider value={refreshData}>
      <Heading level={5}>
        My absence
      </Heading>

    </RefreshDataContext.Provider>
  );
}