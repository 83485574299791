import { Text, Heading, View } from '@aws-amplify/ui-react';
import { IEvent } from '../helpers/sunday';
import { useContext } from 'react';
import { DebugContext, LanguageContext } from '../pages/MainPage';
import { compact, concat, flow, get, join, map, uniq } from 'lodash/fp';
import { longDateOptions, timeOptions, toLocaleString } from '../helpers/functional';

interface EventTitleProps {
  event: IEvent;
}

export const EventTitle = ({event}: EventTitleProps) => {
  const language = useContext(LanguageContext);

  return (
    <View paddingBottom="medium">
      <Heading level={6}>
        {event.eventStartDate.toLocaleString(language, longDateOptions)}
        {event.linkedEvents && event.linkedEvents.length > 0 && ` & ${flow(
          get('linkedEvents'),
          map(flow(
            get('eventStartDate'),
            toLocaleString(language, timeOptions),
          )),
          join(' & '),
        )(event)}`}
      </Heading>
      <Text>
        {flow(
          get('linkedEvents'),
          map(({eventName}: {eventName: string}) => eventName),
          concat(event.eventName),
          compact,
          uniq,
          join(', '),
        )(event)}
      </Text>
      <Text fontSize="small">
        {flow(
          get('linkedEvents'),
          map(({eventDescription}: {eventDescription: string}) => eventDescription),
          concat(event.eventDescription),
          compact,
          uniq,
          join(', '),
        )(event)}
      </Text>
      { useContext(DebugContext) && (
        <Text fontSize="0.9rem" variation="info">
          Sunday eventId: {event.eventId}
        </Text>
      )}
    </View>
  )
};