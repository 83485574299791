import { Flex, View } from "@aws-amplify/ui-react";
import { withAuth } from "../auth/AuthProvider";
import { createContext, useContext } from "react";
import { Absences } from "../components/Absences";
import { getCurrentUser } from "../helpers/sunday";

export const RefreshDataContext = createContext(() => {});
export const useRefreshData = () => useContext(RefreshDataContext);

interface ProfilePageProps {
}

export const ProfilePage: React.FC<ProfilePageProps> = withAuth((props) => {
  const refreshData = () => {}

  return (
    <RefreshDataContext.Provider value={refreshData}>
      <Flex direction="column" gap="1rem">
        <View padding="medium">
          <Absences userId={getCurrentUser()} />
        </View>
      </Flex>
    </RefreshDataContext.Provider>
  );

})