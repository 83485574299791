export const Duration = {
  minutes: (minutes: number = 1) => minutes * 60 * 1000,
  hours: (hours: number = 1) => hours * 60 * 60 * 1000,
  days: (days: number = 1) => days * 24 * 60 * 60 * 1000,
  weeks: (weeks: number = 1) => weeks * 7 * 24 * 60 * 60 * 1000,
};

export const logError = (...args: any[]) => (bypassObject: any) => {
  console.error(...args, bypassObject);
  return bypassObject;
}

export const logInfo = (...args: any[]) => (bypassObject: any) => {
  console.info(...args, bypassObject);
  return bypassObject;
}

export const log = (...args: any[]) => (bypassObject: any) => {
  console.log(...args, bypassObject);
  return bypassObject;
}

export const getLength = (arr: any[]) => arr.length;
export const makeDate = (date: string) => new Date(date);

export const toLocaleString = (locales?: Intl.LocalesArgument, options?: Intl.DateTimeFormatOptions | undefined) => (date: Date) => date.toLocaleString(locales, options);

export const longDateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export const shortDateOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const timeOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

export const prependStr = (str: string) => (str2: string) => `${str}${str2}`;
export const appendStr = (str: string) => (str2: string) => `${str2}${str}`;

export const addDays = (days: number) => (date: Date) => {
  date.setDate(date.getDate() + days);
  return date;
}

export const addWeeks = (weeks: number) => addDays(7 * weeks);
export const toISOString = (date: Date) => date.toISOString();
export const substring = (start: number, end: number) => (str: string) => str.substring(start, end);
export const setToTimeOfDay = (timeOfDay: string) => (date: string) => new Date(`${date}T${timeOfDay}:00.000Z`);
