import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { handleLogin } from './handle-login';
import { LoginForm } from '../components/LoginForm';
import { getCurrentUser } from '../helpers/sunday';

type AuthContextType = {
  isAuthenticated: boolean;
  login: (
    username: string,
    password: string,
    setLoginErrorMessage: Dispatch<SetStateAction<string>>
  ) => void;
  logout: () => void;
};

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: true,
  login: (
    username: string,
    password: string,
    setLoginErrorMessage: Dispatch<SetStateAction<string>>
  ) => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps {
  children?: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const login = handleLogin(setIsAuthenticated);

  const logout = () => {
    localStorage.removeItem('personId');
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const withAuth = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const AuthenticatedComponent: React.FC<P> = (props) => {
    const { isAuthenticated } = useAuth();

    // If not authenticated, render the LoginForm
    if (!isAuthenticated || !getCurrentUser()) return <LoginForm />;

    // If authenticated, render the wrapped component
    return <WrappedComponent {...props} />;
  };

  return AuthenticatedComponent;
};
