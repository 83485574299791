import { Flex, View } from "@aws-amplify/ui-react";
import { IEventRequest, eventWithin7Days, getCurrentUser } from "../helpers/sunday"
import { RequestsCollection } from "../components/RequestsCollection";
import { ReactNode } from "react";
import { NextEvent } from "../components/NextEvent";

interface HomePageProps {
  requests: IEventRequest[];
  children?: ReactNode;
}

export const HomePage = ({requests, children}: HomePageProps) => {
  return (
    <Flex direction="column" gap="1rem">
      <View padding="medium">
        <NextEvent
          requests={requests.filter(eventWithin7Days)}
          forPersonId={getCurrentUser()}
          title="Next event"
        />
        <RequestsCollection
          requests={requests}
          confirmed={false}
          forPersonId={getCurrentUser()}
          title="Awaiting your response"
        />
        <RequestsCollection
          requests={requests}
          confirmed={true}
          forPersonId={getCurrentUser()}
          title="Your schedule"
        />
      </View>
      {children}
    </Flex>
  )
}
