import { Tabs, TabItem, View, Button } from '@aws-amplify/ui-react';
import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { FaHome, FaRandom } from "react-icons/fa";
import { IEventRequest, apiChurchToolsRequests, apiChurchToolsEvents } from '../helpers/sunday';
import { HomePage } from './HomePage';
import { Duration } from '../helpers/functional';
import { TeamPage } from './TeamPage';
import { useAuth, withAuth } from '../auth/AuthProvider';
import { useNavigate } from 'react-router-dom';

const admin = true;
export const DebugContext = createContext(false);
const defaultLanguage = 'en-GB';
export const LanguageContext = createContext(defaultLanguage);
export const RefreshDataContext = createContext(() => {});
export const useRefreshData = () => useContext(RefreshDataContext);

interface DebugButtonProps {
  debug: boolean;
  setDebug: Dispatch<SetStateAction<boolean>>;
}

const DebugButton = ({debug, setDebug}: DebugButtonProps) => {
  const { logout } = useAuth();
  return true ? (
    <View paddingLeft="medium">
      <Button size="small" variation="warning" onClick={() => setDebug(!debug)}>Turn debug { debug ? "off" : "on" }</Button>
      <Button size="small" variation="warning" onClick={logout}>Log out</Button>
    </View>
  ) : <></>
}

interface MainPageProps {
  initialTab?: number;
}

export const MainPage: React.FC<MainPageProps> = withAuth(({initialTab = 0}) => {
  const [tab, setTab] = useState(initialTab);
  const [requests, setRequests] = useState([] as IEventRequest[]);
  const [events, setEvents] = useState([] as IEventRequest[]);
  const [language, setLanguage] = useState(defaultLanguage);
  const [debug, setDebug] = useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const refreshData = () => {
    apiChurchToolsRequests(setRequests, logout);
    apiChurchToolsEvents(setEvents, logout);
  };

  useEffect(() => {
    if (navigator && navigator.language) {
      setLanguage(navigator.language);
    }
  }, []);

  const navigateHome = () => {
    navigate('/home');
    setTab(0);
  }

  const navigateTeam = () => {
    navigate('/team');
    setTab(1);
  }

  const handleClick = (val: string | number) => {
    if (`${val}` === '0') navigateHome();
    if (`${val}` === '1') navigateTeam();
  }
  
  useEffect(() => {
    refreshData();
    const refreshInterval = setInterval(refreshData, Duration.minutes(5));
    return () => {
      clearInterval(refreshInterval);
    };
  }, []);
  
  return (
    <RefreshDataContext.Provider value={refreshData}>
      <LanguageContext.Provider value={language}>
        <DebugContext.Provider value={debug}>
          <Tabs
            spacing='equal'
            currentIndex={tab}
            onChange={handleClick}
          >
              <TabItem fontSize="small" title={<>
                <FaHome /> Home
              </>}>
                <HomePage requests={requests}>
                  <DebugButton debug={debug} setDebug={setDebug} />
                </HomePage>
              </TabItem>
            { admin &&
              <TabItem fontSize="small" title={<>
                <FaRandom /> My Team
              </>}>
                <TeamPage events={events}>
                  <DebugButton debug={debug} setDebug={setDebug} />
                </TeamPage>
              </TabItem>
            }
          </Tabs>
        </DebugContext.Provider>
      </LanguageContext.Provider>
    </RefreshDataContext.Provider>
  )
});
