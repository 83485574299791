import { useState } from 'react';
import { LoginForm as UILoginForm } from '../ui-components';
import { View, Flex, Heading, Text, Expander, ExpanderItem, Grid } from '@aws-amplify/ui-react';
import config from '../config.json';
import { useAuth } from '../auth/AuthProvider';

export const LoginForm = () => {
  const { login } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Grid templateColumns='1fr 8fr 1fr'>
      <Flex
        column={2}
        direction='column'
        alignItems='center'
        gap='1.2rem'
        width='100%'
      >
        <View textAlign='center'>
          <Heading level={4} marginTop="3rem">{config.welcomeMessage}</Heading>
          <Heading level={6}>Please login to ChurchTools</Heading>
        </View>
        {errorMessage && (
          <View
            backgroundColor='red.10'
            marginTop='0.8rem'
            padding='0.6rem'
            borderRadius='medium'
            textAlign='center'
          >
            <Text>{errorMessage}</Text>
          </View>
        )}
        <View width='100%'>
          <UILoginForm onSubmit={({username, password}) => !username || !password ?
              setErrorMessage('Please provide Email address and password') :
              login(username, password, setErrorMessage)} />
        </View>
        <View width='100%' marginTop='large'>
          <Expander isCollapsible={true}>
            <ExpanderItem title="Why do you need my credentials?" value='credentials-explanation'>
              We need your credentials for ChurchTools to share just relevant information for you as well as to protect access to ChurchTool's information. The credentials are forwarded to ChurchTools for the login and will not be stored in the Volunteer Scheduler.
            </ExpanderItem>
          </Expander>
        </View>

      </Flex>
    </Grid>
  );
}
