import { Collection, Flex, View } from "@aws-amplify/ui-react";
import { IEventRequest } from "../helpers/sunday";
import { filter, flow, isEqual, map, uniq, uniqWith } from "lodash/fp";
import { EventTeamCard, GroupEventDaysWithDetails } from "../components/EventTeamCard";

interface TeamPageProps {
  events: IEventRequest[];
  children?: React.ReactNode;
}

const groupForEventDays = (events: IEventRequest[]): GroupEventDaysWithDetails[] => flow(
  map(({eventDay}: IEventRequest) => eventDay),
  uniq,
  map((eventDay: string) => ({
    eventDay,
    eventDetails: flow(
      filterByEventDay(eventDay),
      map(({eventStartDate, eventName, eventDescription}: IEventRequest) => ({
        eventStartDate: new Date(eventStartDate),
        eventName,
        eventDescription,
      })),
      uniqWith(isEqual),
    )(events),
    })),
)(events)

const filterByEventDay = (eventDay: string) => filter((event: IEventRequest) => event.eventDay === eventDay);

export const TeamPage = ({events, children}: TeamPageProps) => (
  <Flex direction='column' gap='1rem'>
    <View padding='medium'>
      <Collection type="list" items={groupForEventDays(events)}>
        {(event: GroupEventDaysWithDetails, index: number) => (
          <EventTeamCard key={index} event={event} requests={filterByEventDay(event.eventDay)(events)} />
        )}
      </Collection>
    </View>
    {children}
  </Flex>
);
