import { API } from "aws-amplify";
import { AxiosError, isAxiosError } from "axios";
import { flow, map } from "lodash/fp";
import { Dispatch, SetStateAction } from "react"

export interface IAbsence {
  absenceId: number;
  comment: string;
  absenceReasonId: number;
  absenceReasonName: string;
  startDate: Date;
  endDate: Date;
}

type ApiChurchToolsAbsences = (
  setAbsences: Dispatch<SetStateAction<IAbsence[]>>,
  userId: number | null,
  logout: () => void,
) => void;

const fixDates = ({startDate, endDate, ...rest}: IAbsence) => ({
  ...rest,
  startDate: new Date(startDate),
  endDate: new Date(endDate),
})

export const apiChurchToolsAbsences: ApiChurchToolsAbsences = async (setAbsences, userId, logout) => {
  if (!userId) return;
  if (['development', 'test'].includes(process.env.NODE_ENV)) {
    flow(
      map(fixDates),
      setAbsences,
    )(require('./example-absences.json'));
    return;
  }

  try {
    const result: IAbsence[] = (await API.get('churchToolsApiProxy', '/absences', {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      response: true,
      withCredentials: true,
      queryStringParameters: {personId: userId},
    })).data;
    // console.log("Result", result);

    flow(
      map(fixDates),
      setAbsences,
    )(result);
    
  } catch (error) {
    if (!isAxiosError(error)) throw error;
    const { response } = (error as AxiosError);
    if (response?.status === 401) logout();
    console.error('Error retrieving requests data from ChurchTools API', response);
    return {
      data: response?.data,
      status: response?.status,
    }    
  }
}
