import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { MainPage } from './pages/MainPage';
import { ProfilePage } from './pages/ProfilePage';

const App: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<MainPage initialTab={0} />} />
      <Route path="/team" element={<MainPage initialTab={1} />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
  </Router>
)

export default App;
