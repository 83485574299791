import { API } from "aws-amplify";
import { Dispatch, SetStateAction } from "react";
import { AxiosError, isAxiosError } from 'axios';


const signInToChurchTools = async (username: string, password: string) => {
  try {
    // console.log('Credentials:', {username, password});
    const result = await API.post('churchToolsApiProxy', '/login', {
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
      response: true,
      withCredentials: true,
      body: {
        username,
        password,
      },
    });
    console.log('Login successful', result);
    
    return result;
  } catch (error) {
    if (!isAxiosError(error)) throw error;
    const { response } = (error as AxiosError);
    console.error('Login failed', response);
    return {
      data: response?.data,
      status: response?.status,
    }
  }
};

export const checkAuthState = async (setIsLoggedIn: Dispatch<SetStateAction<boolean>>) => {
  try {
    const personId = localStorage.getItem('personId');
    if (!personId) throw new Error("");
    setIsLoggedIn(true);

  } catch (error) {
    setIsLoggedIn(false);
    console.log('User not logged in');
  }
}

export const handleLogin = (
  setIsLoggedIn: Dispatch<SetStateAction<boolean>>
) => async (
  username: string,
  password: string,
  setLoginErrorMsg: Dispatch<SetStateAction<string>>,
) => {
  const result = await signInToChurchTools(username, password);
  console.log("handleLogin Result:", result);
  if (!(result.status === 200)) {
    setLoginErrorMsg(result.data.message);
    return;
  }
  const { personId } = result.data;
  localStorage.setItem('personId', personId);

  setLoginErrorMsg('');
  setIsLoggedIn(true);
}
